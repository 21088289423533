export default {
  namespaced: true,
  getters: {
    list(state, getters, rootState) {
      return {
        method: "get",
        url: "oppo/list",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Accept-Language": rootState.locale,
          Authorization: `Bearer ${rootState.access.token}`,
        },
      };
    },
    add(state, getters, rootState) {
      return {
        method: "post",
        url: "/oppo/add",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Accept-Language": rootState.locale,
          Authorization: `Bearer ${rootState.access.token}`,
        },
      };
    },
    directResponse(state, getters, rootState) {
      return {
        method: "PATCH",
        url: "https://sales.masiya.net/api/oppo/direct_response",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Accept-Language": rootState.locale,
          Authorization: `Bearer ${rootState.access.token}`,
        },
      };
    },
  },
};
