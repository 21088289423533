import Vue from "vue";
import VueRouter from "vue-router";
import middlewarePipeline from "@/middleware/middlewarePipeline";
import guest from "@/middleware/guest";
import auth from "@/middleware/auth";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard"),
    meta: {
      title: "Dashboard",
      middleware: [auth],
    },
  },
  {
    path: "/auth/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "Login",
      middleware: [guest],
    },
  },
  {
    path: "/auth/change-password",
    name: "change-password",
    component: () => import("@/views/ChangePassword.vue"),
    meta: {
      title: "Change Password",
      middleware: [auth],
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const { middleware } = to.meta;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
