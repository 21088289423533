export default {
  namespaced: true,
  getters: {
    login(state, getters, rootState) {
      return {
        method: "post",
        url: "/auth/login",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Accept-Language": rootState.locale,
        },
      };
    },
    changePassword(state, getters, rootState) {
      return {
        method: "patch",
        url: "/auth/change_password",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Accept-Language": rootState.locale,
          Authorization: `Bearer ${rootState.access.token}`,
        },
      };
    },
    tokenCheck(state, getters, rootState) {
      return {
        method: "get",
        url: "/auth/token_check",
        headers: {
          "Content-type": "application/json",
          "Accept-Language": rootState.locale,
          Accept: "*/*",
          Authorization: `Bearer ${rootState.access.token}`,
        },
      };
    },
  },
};
