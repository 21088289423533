import Vue from "vue";
import Vuex from "vuex";
import auth from "@/network/auth";
import opportunities from "@/network/opportunities";
import admin from "@/network/admin";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    access: null,
    locale: "en",
    loading: false,
    errors: [],
  },
  mutations: {
    SetUser(state, payload) {
      if (payload === null) {
        localStorage.removeItem("user");
        state.user = null;
      } else {
        state.user = payload;
        localStorage.setItem("user", JSON.stringify(payload));
      }
    },
    SetAccess(state, payload) {
      if (payload === null) {
        localStorage.removeItem("access");
        state.access = null;
      } else {
        state.access = payload;
        localStorage.setItem("access", JSON.stringify(payload));
      }
    },
  },
  actions: {},
  modules: {
    auth,
    opportunities,
    admin,
  },
});
