export default {
  namespaced: true,
  getters: {
    list(state, getters, rootState) {
      return {
        method: "get",
        url: "user_admin/list",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Accept-Language": rootState.locale,
          Authorization: `Bearer ${rootState.access.token}`,
        },
      };
    },
    add(state, getters, rootState) {
      return {
        method: "post",
        url: "user_admin/add",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Accept-Language": rootState.locale,
          Authorization: `Bearer ${rootState.access.token}`,
        },
      };
    },
    edit(state, getters, rootState) {
      return {
        method: "put",
        url: "user_admin/edit",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Accept-Language": rootState.locale,
          Authorization: `Bearer ${rootState.access.token}`,
        },
      };
    },
  },
};
