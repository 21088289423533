<template>
  <v-app>
    <v-main class="grey lighten-3">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    let access = localStorage.getItem("access");
    let user = localStorage.getItem("user");
    if (access) {
      access = JSON.parse(access);
      this.$store.commit("SetAccess", access);
    }
    if (user) {
      user = JSON.parse(user);
      this.$store.commit("SetUser", user);
    }
  },
  methods: {
  },
  data: () => ({
    //
  }),
};
</script>
