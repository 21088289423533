export default function auth({ next }) {
  const access = localStorage.getItem("access");
  if (!access) {
    return next({
      name: "Login",
    });
  }

  return next();
}
